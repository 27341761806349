<template>

  <div :class="{'uploader': true, 'gloading': isLoading}">
    {{$t('profile_menu_edit_profile_change_avatar')}}
    <input type="file" ref="file" accept=".jpg, .jpeg, .png" @change="changeFiles">
  </div>

</template> 


<script setup lang="ts">

import { getRandomString } from '@/types/other'

const { $api, $popup } = useNuxtApp();

const emits = defineEmits([
  'changed',
  'uploaded',
  'errored'
])

const files = ref([])
const file = ref(null)
const thumbs = ref([])
const isLoading = ref(false)


const changeFiles = () => {  
  console.log(file.value.files);
  for( var i = 0; i < file.value.files.length; i++ ){                
    file.value.files[i].uploading_id = getRandomString(10);
    files.value.push(file.value.files[i])        
    emits('changed', file.value.files[i].uploading_id); 
  }
  submitFiles();
}

const uploadFile = (file) => { 
  
  let formData = new FormData();  
  formData.append('file', file, file.name); 

  isLoading.value = true;
 
  $api.postGrowersAvatars(useAuth().getId(), formData) 
  .then(response => { 
    isLoading.value = false;
    handleUploaded(file.uploading_id, response.data.url);
  })
  .catch(error => {
    isLoading.value = false;
    $popup.error('Error uploading file');
    handleError(file.uploading_id);
  });

}

const submitFiles = () => {  
  for(var x in files.value){        
    uploadFile(files.value[x]);
  }
  files.value = [];
}

const handleUploaded = (uploading_id: string, data: string) => {      
  emits('uploaded', data, uploading_id); 
}

const handleError = (uploading_id: string) => {      
  emits('errored', uploading_id); 
}





</script>
   

<style scoped>

.uploader{
  background-color: #767676;
  color: #FFFFFF;
  display: inline-block;
  padding: 0.78571429em 1.5em 0.78571429em;
  font-weight: bold;
  line-height: 1em;
  cursor: pointer;
  font-size: 0.75rem;
  border-radius: 20px;
  position: relative;
  text-align: center;
}
input[type="file"]{
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';  
  direction: ltr;
  cursor: pointer;
  width: 100%;
  height: 100%;
} 


</style>
